<template>
  <div class="login-screen">
    <FormSignIn v-bind:redirect_url="redirect_url"></FormSignIn>
  </div>
</template>

<script>
// @ is an alias to /src
import FormSignIn from "@/components/form-signin.vue";

export default {
  name: "SignIn",
  props: {
    redirect_url: {
      type: String,
      required: false,
      default: ""
    }
  },
  components: {
    FormSignIn
  },
  mounted: function() {
    this.$store.dispatch("reset");
  }
};
</script>

<style scoped>
.login-screen {
  background: #d2d6de;
  background: rgb(209, 227, 226);
  background: linear-gradient(
    180deg,
    rgba(209, 227, 226, 1) 0%,
    rgba(154, 171, 175, 1) 67%
  );
  position: absolute;
  width: 100%;
  bottom: 0;
  top: 0;
  box-shadow: 0px 0px 6px grey;
}
</style>
